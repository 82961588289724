.content {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border: none;
  outline: none !important;
  
}
.container {
  display: flex;
  flex-direction: column;
  z-index: 10000;
  
}
.top {
  min-width: 90vw;
  height: 10vh;
  background-color: #0b2447;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  z-index: 100;
  box-shadow: 0px 1px 5px;
}
.bottom {
  width: 90vw;
  height: 600px;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-top: -35px;
  border-radius: 20px;
  border: 1px dashed #0b2447;
  align-items: center;
  padding: 20px;

  z-index: 99;
}
.topText {
  font-size: larger;
  font-weight: bolder;
  color: #2196f3;
}
.detailDiv{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 550px;
  
  padding: 10px;
  align-items: center;
  justify-content: space-evenly;
}
.mapDiv{
  width: 800px;
  height: 500px;
  display: flex;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  
}
.cordinates{
  width: 500px;
  max-height: 500px;
  height: 500px;
  display: flex;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
  rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  overflow-y: scroll;
  flex-direction: column;
  
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.cordinatesText{
  font-size: large;
  font-weight: bold;
  color: #0b2447;
  margin: 10px;
  margin-top: 20px;
}
.btnDiv{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  height: 300px;
}
.btn{
  width: 100px;
  height: 70px;
  border: none;
  border-radius: 10px;
  background-color: #2196f3;
  color: white;
  cursor: pointer;
}