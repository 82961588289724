.container {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #0b2447;
  justify-content: center;
  align-items: center;
}
.main {
  width: 95%;
  height: 90%;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
}
.left {
  width: 20%;
  height: 100%;
  display: flex;
  background-color: #2196f3;
  flex-direction: column;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  justify-content: space-between;
}
.right {
  width: 80%;
  height: 100%;
  display: flex;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.usersContainer {
  max-width: 100%;
  max-height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.userCardDiv {
  min-width: 300px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  background-color: #0b2447;
  border-radius: 20px;
  margin: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.userCardImgDiv {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.userCardImg {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
}
.userCardDetailDiv {
  width: 100%;
  height: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.userCardDetail {
  font-size: larger;
  font-weight: bolder;
  color: #2196f3;
}
.userCardBtn {
  width: 150px;
  height: 30px;
  color: #0b2447;
  border-radius: 10px;
  background-color: #2196f3;
  border: none;
  margin-top: 20px;
  cursor: pointer;
}
.quoteContainer {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  align-items: center;
  padding: 20px;
}
.quoteCardDiv {
  display: flex;
  flex-direction: column;
  min-width: 900px;
  min-height: 400px;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.profilePicDiv {
  display: flex;
  width: 100%;
  height: 250px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.profilePic {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
}
.profileName {
  font-size: larger;
  font-weight: bolder;
  color: white;
}
.usersDiv {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.usersDiv::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #0b2447;
  transform: scaleX(0);
  transition: 300ms;
}
.usersDiv:hover::after {
  transform: scaleX(1);
}

.usersText {
  font-size: larger;
  font-weight: bolder;
  color: white;
  z-index: 10;
}
.logoutDiv {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.logoutIcon {
  width: 50px;
  cursor: pointer;
}
.iconMobDiv {
  display: none;
}
.backToLogin {
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  border-radius: 20px;
}
.quoteDetailDiv {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 50px;
}
.quoteDetail {
  font-size: large;
  font-weight: bolder;
  color: #0b2447;
}
.quoteDetailMessageDiv {
  width: 100%;
  display: flex;

  padding: 10px;
}
.quoteDetailMessage {
  color: #0b2447;
  font-size: larger;
}
.commentContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-wrap: wrap;
  justify-content: center;
}
.commentCardDiv {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 500px;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  margin: 10px;
  border-radius: 20px;
  overflow-y: scroll;
}
.commentCardUserDiv {
  display: flex;
  justify-content: center;

  width: 100%;
  height: 50px;
}
.commentCardUserText {
  font-size: large;
  font-weight: bold;
  color: #0b2447;
}
.commentCardDetailDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
}
.commentCardDetailText {
  font-size: large;
  text-justify: inter-word;
  text-align: justify;
}
.orderContainer {
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 30px;
}
.orderCardDiv {
  position: relative;
  width: 900px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background-color: #0b2447;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  margin-top: 10px;
}
.orderCardMap {
  width: 100%;
  height: 375px;
  background-color: #2196f3;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.orderStatusDiv {
  position: absolute;
  top: 0;
  right: 0;
  width: 200px;
  height: 50px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(6px);
  display: flex;
  z-index: 99;
  padding-left: 10px;
  justify-content: center;
  
}
.orderStatusText {
  font-size: medium;
  font-weight: bold;
  color: #0b2447;
}
.orderCardDetailDiv {
  width: 100%;
  height: 125px;
  display: flex;
  flex-direction: row;
}
.orderCardDetail {
  min-width: 33.33%;
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.orderCardDetailText {
  font-size: large;
  font-weight: bold;
  color: #2196f3;
}
@media only screen and (max-width: 600px) {
  .container {
    height: 100svh;
  }
  .main {
    height: 95%;
  }
  .left {
    width: 15%;
    justify-content: center;
  }
  .right {
    width: 85%;
  }
  .profilePicDiv {
    display: none;
  }
  .usersDiv {
    display: none;
  }
  .iconMobDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .iconMob {
    width: 40px;
    margin-top: 10px;
  }
  .logoutIcon {
    width: 35px;
    margin-top: 15px;
    margin-left: 7px;
  }
}
