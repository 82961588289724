.content {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border: none;
  outline: none !important;
  z-index: 1000;
}
.container {
  display: flex;
  flex-direction: column;
}
.top {
  min-width: 600px;
  height: 10vh;
  background-color: #0b2447;
  display: flex;
  border-radius: 20px;
  z-index: 100;
  box-shadow: 0px 1px 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.bottom {
  width: 600px;
  height: 60vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-top: -35px;
  border-radius: 20px;
  border: 1px dashed #0b2447;
  align-items: center;
  padding: 20px;

  z-index: 99;
}
.topText {
  font-size: larger;
  font-weight: bolder;
  color: #2196f3;
}
.bottomTextDiv{
  display: flex;
  width: 100%;
  height: 55vh;
  margin-top: 10px;
}
.bottomText{
  font-size: large;
  color: #0b2447;
}
.btnDiv{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.btn{
  width: 100px;
  height: 50px;
  background-color: #2196f3;
  color: white;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}