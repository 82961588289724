.content {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border: none;
  outline: none !important;
  z-index: 1000;
}
.container {
  display: flex;
  flex-direction: column;
}
.top {
  min-width: 90vw;
  height: 10vh;
  background-color: #0b2447;
  display: flex;
  border-radius: 20px;
  z-index: 100;
  box-shadow: 0px 1px 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.bottom {
  width: 90vw;
  height: 80vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-top: -35px;
  border-radius: 20px;
  border: 1px dashed #0b2447;
  align-items: center;
  padding: 20px;

  z-index: 99;
}
.topText {
  font-size: larger;
  font-weight: bolder;
  color: #2196f3;
}
.userDetail {
  width: 100%;
  min-height: 20vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #0b2447;
}
.userDetailImgDiv {
  width: 25%;
  max-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.userDetailImg {
  width: 100px;
  border-radius: 50%;
}
.userDetailTextDiv {
  width: 25%;
  max-height: 200px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.userDetailTextImg {
  width: 40px;
  margin-right: 10px;
}
.userDetailText {
  font-size: larger;
  font-weight: bolder;
  color: #0b2447;
}
.orderComment {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.orders {
  display: flex;
  flex-direction: column;
  min-width: 48%;
  height: 60vh;
  border-right: 1px dashed #0b2447;
  overflow-y: auto;
  
  flex-wrap: wrap;
}

.orderCardDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
}
.orderCard {
  display: flex;
  flex-direction: column;
  width: 90%;
  min-height: 400px;
  background-color: #0b2447;
  border-radius: 20px;
  margin-top: 20px;
}
.orderCardMapDiv {
  display: flex;
  width: 100%;
  height: 275px;
  background-color: #2196f3;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.orderDetailDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 125px;
}
.orderDetail {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33.33%;
  height: 100%;
  flex-direction: column;
}
.orderDetailTextTitle {
  font-size: large;
  font-weight: bold;
  color: #2196f3;
}
.orderDetailText {
  font-size: large;
  font-weight: bold;
  color: #2196f3;
}
.comments {
  display: flex;
  flex-direction: column;
  min-width: 48%;
  height: 100%;
}
.commentCardDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  overflow-y: hidden;
  overflow-x: scroll;
}
.commentCard {
  min-width: 700px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.commentCardTitle {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.commentCardTitleText {
  font-size: larger;
  font-weight: bold;
  color: #0b2447;
}
.commentCardTextDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.commentCardText {
  font-size: large;
  color: #0b2447;
}
