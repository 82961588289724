@font-face {
  font-family: Poppins;
  src: url("Poppins-SemiBold.ttf");
  font-weight: normal;
}

@font-face {
  font-family: Poppins;
  src: url("Poppins-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Poppins;
  src: url("Poppins-ExtraBold.ttf");
  font-weight: bolder;

}

