.container {
  width: 100%;
  height: 100vh;
  background-color: #0b2447;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.loginDiv {
  display: flex;
  flex-direction: column;
  width: 550px;
  height: 450px;
  background-color: white;
  border-radius: 20px;
  align-items: center;
}
.logoDiv {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 10px;
}
.logo {
  width: 200px;
}
.notAdmin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
.adminPanelTextDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.adminPanelText {
  font-weight: bolder;
  font-size: large;
  color: #0b2447;
}
.formDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.inputComponents {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 300px;
  border: 1px solid gray;
  border-radius: 20px;
  padding: 6px;
}
.inputIcons {
  width: 30px;
  aspect-ratio: 1/1;
  margin-top: -5px;
  margin-left: 10px;
}
.input {
  height: 50px;
  width: 300px;
  outline: none;
  border: none;
}
.btn {
  height: 60px;
  width: 150px;
  border-radius: 8px;
  outline: none;
  border: none;
  background-color: #277ffb;
  margin-top: 24px;
  font-weight: bolder;
  font-size: 13px;
  color: white;
  cursor: pointer;
}
.btn:disabled {
  background-color: gray;
  cursor: auto;
}
@media only screen and (max-width: 600px) {
  .container {
    height: 100svh;
  }
  .loginDiv {
    display: flex;
    flex-direction: column;
    width: 350px;
    height: 400px;
  }
}
